@import url('https://fonts.googleapis.com/css?family=Poppins');

body {
    font-family: 'Poppins', sans-serif;
    background-color:#ced2ba;
    margin: 0px;
}

header {
    font-family: 'Poppins', sans-serif;
    color: #415B41;
    font-size: 36px;
}

p {
    color: #415B41;
    margin-left: 10px;
    font-size: 24px;
}

.container {
    scroll-snap-type: y mandatory;
    height: 100vh;
    overflow-y: scroll;
}

.section {
    height: 100vh;
    scroll-snap-align: center;
}
